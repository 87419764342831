import { add, addDays, addMinutes, setDay, setHours, setMinutes } from "date-fns";
import { concat, filter, fromPromise, map, merge, pipe } from "wonka";
import { ObjectEnum } from "../types/enums";
import { Override } from "../types/index";
import { dateToStr, dayOfWeekToNumber, roundTimeToChunk, roundTimeToNextChunk, strToDate } from "../utils/dates";
import { parseEventKey } from "../utils/events";
import { scanForPolicyStartOrEndHour, timeFitsInUserPolicy } from "../utils/users";
import { deserialize, upsert } from "../utils/wonka";
import { DayOfWeek } from "./Calendars";
import {
  Priority,
  ProjectInclude,
  Reindex as ReindexDao,
  ReindexDirection as ReindexDirectionDao,
  Smurf as SmurfDto,
  SubscriptionType,
  Task as TaskDto,
  TaskDefaults as TaskDefaultsDto,
  TaskInstance as TaskInstanceDto,
  TaskStatus as TaskStatusDto,
} from "./client";
import { AssistType, Category, EventColor, EventType, PrimaryCategory } from "./EventMetaTypes";
import { EventKey } from "./Events";
import { ThinPerson } from "./People";
import { dtoToProject, IncludeProject, Project, Smurf } from "./Projects";
import { NotificationKeyStatus, nullable, TransformDomain } from "./types";
import { TimePolicyType, User } from "./Users";

export { ProjectInclude } from "./client";

export const isTask = (item: unknown): item is Task => {
  if (!item || typeof item !== "object" || !item?.["type"]) return false;
  return !!(item as Task).type && (item as Task).type === AssistType.Task.key;
};

export class TaskStatus extends ObjectEnum {
  static New = new TaskStatus(TaskStatusDto.NEW, false, "Active");
  static Scheduled = new TaskStatus(TaskStatusDto.SCHEDULED, false, "Scheduled");
  static InProgress = new TaskStatus(TaskStatusDto.IN_PROGRESS, false, "In-progress");
  static Complete = new TaskStatus(TaskStatusDto.COMPLETE, true, "Complete");
  static Cancelled = new TaskStatus(TaskStatusDto.CANCELLED, true, "Cancelled");
  static Archived = new TaskStatus(TaskStatusDto.ARCHIVED, true, "Archived");

  static Active = [TaskStatus.New, TaskStatus.Scheduled, TaskStatus.InProgress, TaskStatus.Complete];

  constructor(public readonly status: TaskStatusDto, public readonly done: boolean, public readonly label: string) {
    super(status);
  }
}

export enum ReindexDirection {
  Before = "before",
  After = "after",
}

export type Reindex = Override<
  ReindexDao,
  {
    relativeTaskId: number;
    reindexDirection: ReindexDirection;
  }
>;

export type TaskDefaults = Override<
  TaskDefaultsDto,
  {
    category: Category;
  }
>;

export enum TaskInstanceStatus {
  Done = "DONE",
  Active = "ACTIVE",
  Pending = "PENDING",
  Aborted = "ABORTED",
}

export type TaskInstance = Override<
  TaskInstanceDto,
  {
    readonly start: Date;
    readonly end: Date;
    readonly status: TaskInstanceStatus;
    readonly calendarId: number;
    readonly eventKey: EventKey;
  }
>;

export type Task = Override<
  TaskDto,
  {
    readonly id: number;
    readonly created?: Date;
    readonly updated?: Date;
    readonly finished?: Date | null;
    readonly projects?: Project[];
    readonly instances?: TaskInstance[];
    readonly effectivePriority?: Smurf;

    readonly deleted?: boolean;

    title: string;
    status?: TaskStatus;
    eventCategory: PrimaryCategory;
    due?: Date | null;

    snoozeUntil?: Date | null;
    eventColor?: EventColor;

    priority?: Smurf;

    invitees?: ThinPerson[];
  }
>;

/* Sorting functions */

export const byTitle = (a: Task, b: Task) => {
  if (a.title === b.title) return 0;
  return a.title < b.title ? -1 : 1;
};

export const byDue = (a: Task, b: Task) => {
  if (a.status !== b.status && (a.status === TaskStatus.Complete || b.status === TaskStatus.Complete)) {
    if (a.status === TaskStatus.Complete) return 1;
    if (b.status === TaskStatus.Complete) return -1;
  }
  if (a.due?.getTime() === b.due?.getTime()) return byTitle(a, b);
  if (!a.due) return 1;
  if (!b.due) return -1;
  return a.due.getTime() - b.due.getTime();
};

export const byIndex = (a: Task, b: Task) => {
  if (a.index === b.index) return byDue(a, b);
  if (undefined === a.index) return b.index!;
  if (undefined === b.index) return a.index!;
  return a.index - b.index;
};

export const byFinished = (a: Task, b: Task) => {
  if (a.finished?.getTime() === b.finished?.getTime()) return byDue(a, b);
  if (!a.finished) return 1;
  if (!b.finished) return -1;
  return a.finished.getTime() - b.finished.getTime();
};

export const byScheduled = (a: Task, b: Task) => {
  const nextChunkA = a?.instances?.[0]?.start?.getTime() || Infinity;
  const nextChunkB = b?.instances?.[0]?.start?.getTime() || Infinity;

  if (nextChunkA === nextChunkB) return byFinished(a, b);
  return nextChunkA - nextChunkB;
};

export const byTimeChunksRequired = (a: Task, b: Task) => {
  if (a.timeChunksRequired === b.timeChunksRequired) return byDue(a, b);
  if (undefined === a.timeChunksRequired) return 1;
  if (undefined === b.timeChunksRequired) return -1;
  return a.timeChunksRequired - b.timeChunksRequired;
};

export const byStatus = (a: Task, b: Task) => {
  if (a.status === b.status) return byScheduled(a, b);
  if (!a.status || TaskStatus.Archived === a.status) return 1;
  if (!b.status || TaskStatus.Archived === b.status) return -1;
  return a.status.key < b.status.key ? -1 : 1;
};

export const byTimeChunksRemaining = (a: Task, b: Task) => {
  if (a.timeChunksRemaining === b.timeChunksRemaining) return byScheduled(a, b);
  if (undefined === a.timeChunksRemaining) return 1;
  if (undefined === b.timeChunksRemaining) return -1;
  return a.timeChunksRemaining - b.timeChunksRemaining;
};

export function getTaskColor(user: User, task: Task): EventColor | undefined {
  return !!task.eventColor && task.eventColor !== EventColor.Auto
    ? task.eventColor
    : EventColor.getColor(user, task.eventCategory);
}

export type RequestQuery = {
  status?: TaskStatus[] | null;
  project?: number | null;
  priority?: Priority;
  includeProjects?: IncludeProject;
};

export function dtoToTask(dto: TaskDto): Task {
  return {
    ...dto,
    id: dto.id as number,
    title: dto.title || "",
    priority: dto.priority as unknown as Smurf,
    status: !!dto.status ? TaskStatus.get(dto.status) : undefined,
    eventCategory: !!dto.eventCategory
      ? PrimaryCategory.get(dto.eventCategory as unknown as string)
      : PrimaryCategory.TeamMeeting,
    eventColor: !!dto.eventColor ? EventColor.get(dto.eventColor) : EventColor.Auto,
    due: strToDate(dto.due),
    snoozeUntil: nullable(dto.snoozeUntil, strToDate),
    finished: nullable<string, Date>(dto.finished, strToDate),
    created: strToDate(dto.created),
    updated: strToDate(dto.updated),
    projects: dto.projects?.map(dtoToProject),
    instances: dto.instances
      ?.map((i) => ({
        ...i,
        start: strToDate(i.start),
        end: strToDate(i.end),
        status: i.status as unknown as TaskInstanceStatus,
        // Swagger types claim eventKey is an object, it's a string...
        eventKey: i.eventKey as unknown as string,
        calendarId: parseEventKey(i.eventKey as unknown as string).calendarId,
      }))
      .filter((i) => i.status !== TaskInstanceStatus.Aborted)
      .sort((a, b) => (!a.start || !b.start ? +1 : a.start.getTime() - b.start.getTime())),
  };
}

export function taskToDto(task: Partial<Task>): Partial<TaskDto> {
  return {
    ...task,
    priority: task.priority as unknown as TaskDto["priority"],
    status: task.status?.toJSON() as TaskDto["status"],
    eventCategory: task.eventCategory?.toJSON() as TaskDto["eventCategory"],
    eventColor: (EventColor.Auto === task.eventColor ? null : task.eventColor?.toJSON()) as TaskDto["eventColor"],
    due: nullable(task.due, dateToStr),
    snoozeUntil: nullable(task.snoozeUntil, dateToStr),
    finished: nullable(task.finished, dateToStr),
    created: dateToStr(task.created),
    updated: dateToStr(task.updated),

    // we dont augment this, no error, but why send so much back?
    projects: undefined,
    // TODO (ma) backend does not deal with this fully yet.
    // data is not yet usable by UI and re-sending brakes the server
    // @ts-ignore
    googleTask: undefined,
    instances: undefined,
  };
}

const TaskSubscription = {
  subscriptionType: SubscriptionType.Task,
};

export class TasksDomain extends TransformDomain<Task, TaskDto> {
  resource = "Task";
  cacheKey = "tasks";
  pk = "id";

  public serialize = taskToDto;
  public deserialize = dtoToTask;

  watchWs$ = pipe(
    this.ws.subscription$$(TaskSubscription),
    filter((envelope) => !!envelope.data),
    map((envelope) => envelope.data),
    deserialize(this.deserialize)
  );

  watchAll$ = pipe(
    merge([this.upsert$, this.watchWs$]),
    map((items) => this.patchExpectedChanges(items))
  );

  list$$ = (query?: {
    status?: TaskStatus[] | null;
    project?: number | null;
    priority?: Smurf;
    id?: number[] | null;
    instances?: boolean | null;
    includeProjects?: ProjectInclude;
  }) => {
    return pipe(
      fromPromise(
        this.list(
          query as
            | {
                status?: TaskStatusDto[] | null | null;
                project?: number | null;
                priority?: SmurfDto;
                id?: number[] | null | null;
                instances?: boolean | null;
                includeProjects?: ProjectInclude;
              }
            | undefined
        )
      ),
      map((items) => this.patchExpectedChanges(items))
    );
  };

  listAndWatch$$ = (query?: {
    status?: TaskStatus[] | null;
    project?: number | null;
    priority?: Smurf;
    id?: number[] | null;
    instances?: boolean | null;
    includeProjects?: ProjectInclude;
  }) => {
    return pipe(
      concat<Task[] | null>([this.list$$(query), this.watchAll$]),
      upsert((e: Task) => this.getPk(e)),
      map((items: Task[]) => {
        return items
          .filter((i) => !query?.id || query.id.includes(i.id))
          .filter((i) => !query?.status || (!!i.status && query.status.includes(i.status)))
          .filter((i) => !query?.priority || query.priority === i.priority);
      }),
      map((items) => [...items])
    );
  };

  watchId$$ = (id: number, instances?: boolean) => {
    return pipe(
      this.listAndWatch$$({ id: [id], instances }),
      map((items) => items?.find((i) => i.id === id))
    );
  };

  list = this.manageErrors(this.deserializeResponse(this.api.tasks.query3));

  get = this.manageErrors(
    this.deserializeResponse((id: number, instances?: boolean, includeProjects?: boolean) =>
      this.api.tasks.getTask(id, { instances, includeProjects: !!includeProjects ? ProjectInclude.ID : undefined })
    )
  );

  create = this.manageErrors(
    this.deserializeResponse((task: Partial<Omit<Task, "id">>) => {
      const notificationKey = this.generateUid("create");

      this.addNotificationKey(notificationKey, NotificationKeyStatus.Pending, true);

      return this.api.tasks
        .create2(this.serialize(task) as TaskDto, { notificationKey })
        .then((res) => {
          this.updateNotificationKey(notificationKey, NotificationKeyStatus.Requested);
          return res;
        })
        .catch((reason) => {
          console.warn("Request failed, clearing notification key", notificationKey, reason);
          this.updateNotificationKey(notificationKey, NotificationKeyStatus.Failed);
          throw reason;
        });
    })
  );

  update = this.manageErrors(
    this.deserializeResponse((task: Partial<Task> & { id: number }) => {
      const notificationKey = this.generateUid("update", task.id);

      this.addNotificationKey(notificationKey, NotificationKeyStatus.Pending, true);

      return this.api.tasks
        .put2(task.id, this.serialize(task) as TaskDto, { notificationKey })
        .then((res) => {
          this.updateNotificationKey(notificationKey, NotificationKeyStatus.Requested);
          return res;
        })
        .catch((reason) => {
          console.warn("Request failed, clearing notification key", notificationKey, reason);
          this.updateNotificationKey(notificationKey, NotificationKeyStatus.Failed);
          throw reason;
        });
    })
  );

  patch = this.manageErrors(
    this.deserializeResponse(async (id: number, patch: Partial<Task>) => {
      const notificationKey = this.generateUid("patch", id);

      this.expectChange(notificationKey, id, patch, true);

      return this.api.tasks
        .patch3(id, this.serialize(patch), { notificationKey })
        .then((res) => {
          this.updateNotificationKey(notificationKey, NotificationKeyStatus.Requested);
          return res;
        })
        .catch((reason) => {
          console.warn("Request failed, clearing notification key", notificationKey, reason);
          this.clearExpectedChange(notificationKey, NotificationKeyStatus.Failed);
          throw reason;
        });
    })
  );

  reindex = this.manageErrors(
    this.deserializeResponse((task: Task, relativeId: number, direction: ReindexDirection) => {
      const payload: ReindexDao = {
        relativeTaskId: relativeId,
        reindexDirection: direction as unknown as ReindexDirectionDao,
      };

      // FIXME (IW): Add when backend supports ws notifications for reindex
      // const notificationKey = this.generateUid("reindex", task.id);
      // this.addNotificationKey(notificationKey);

      return this.api.tasks
        .reindex(task.id, payload)
        .then((res: TaskDto) => {
          const task = this.deserialize(res);
          this.upsert(task);
          return res;
        })
        .catch((reason) => {
          console.warn("Request failed", reason);
          // this.updateNotificationKey(notificationKey, NotificationKeyStatus.Failed);
          throw reason;
        });
    })
  );
}

export const delayedTaskStartOptions = [
  { label: "No delay", value: 0 },
  { label: "1 hr", value: 1 * 60 },
  { label: "2 hr", value: 2 * 60 },
  { label: "5 hr", value: 5 * 60 },
  { label: "12 hr", value: 12 * 60 },
  { label: "1 day", value: 24 * 60 },
  { label: "2 days", value: 2 * 24 * 60 },
  { label: "3 days", value: 3 * 24 * 60 },
  { label: "1 week", value: 7 * 24 * 60 },
  { label: "2 weeks", value: 2 * 7 * 24 * 60 },
];

export const dueInDaysOptions = [
  { label: "No due date", value: 0 },
  { label: "1 day", value: 1 },
  { label: "2 days", value: 2 },
  { label: "3 days", value: 3 },
  { label: "1 week", value: 7 },
  { label: "2 weeks", value: 14 },
  { label: "4 weeks", value: 28 },
];

export const getUserDefaultSnoozeUntil = (user: User, policy: TimePolicyType): Date => {
  let anchor = new Date();

  if (!!user?.features?.taskSettings?.defaults.delayedStartInMinutes) {
    anchor = addMinutes(anchor, user.features.taskSettings.defaults.delayedStartInMinutes || 0);
  }

  return roundTimeToNextChunk(anchor);
};

export const getUserDefaultDueDate = (user: User, policy: TimePolicyType): Date | null => {
  const fallback = (due: Date): Date => {
    // default to 6:00 PM if date doesn't fit day's time policy
    due.setHours(18);
    return due;
  };

  if (!!user?.features?.taskSettings?.defaults.dueInDays) {
    const due = roundTimeToNextChunk(add(new Date(), { days: user.features.taskSettings.defaults.dueInDays }));
    if (timeFitsInUserPolicy(user, due, policy)) {
      // Should only scan current day for endOfDay;
      const end = scanForPolicyStartOrEndHour(user, due, policy, "end");
      return !!end ? end : fallback(due);
    } else {
      // Reverse scan and find nearest endOfDay to apply to this due date hours
      // NOTE: This matches backend logic.
      const prevEnd = scanForPolicyStartOrEndHour(user, due, policy, "end", true);

      if (!!prevEnd) {
        due.setHours(prevEnd.getHours(), 0, 0);
        return due;
      } else {
        return fallback(due);
      }
    }
  } else {
    return null;
  }
};

export const defaultTask: Partial<Task> = {
  title: "",
  status: TaskStatus.New,
  timeChunksRequired: 4,
  due: addDays(setMinutes(setHours(new Date(), 18), 0), 3),
  eventCategory: PrimaryCategory.SoloWork,
  eventColor: EventColor.Auto,
  projectIds: [],
  minChunkSize: 4,
  maxChunkSize: 32,
  alwaysPrivate: false,
};

export function makeDefaultTask(user?: User | null, forWeekStarting?: Date): Task {
  let dateBasedOnPlanner: Date | undefined = undefined;

  if (forWeekStarting) {
    dateBasedOnPlanner = roundTimeToChunk(
      setHours(
        setDay(
          forWeekStarting,
          dayOfWeekToNumber(
            (user?.features.timePolicies?.work?.startOfWeek as unknown as DayOfWeek) || DayOfWeek.Sunday
          )
        ),
        8 // 8:00 am
      )
    );
  }

  // Return general defaults if no user set defaults
  if (!user?.features?.taskSettings?.defaults) {
    return {
      ...defaultTask,
    } as Task;
  }

  const defaults = user.features.taskSettings.defaults;

  const timePolicy = defaults.category === PrimaryCategory.Personal ? TimePolicyType.Personal : TimePolicyType.Work;

  let snoozeUntil: Date | null =
    !!dateBasedOnPlanner && dateBasedOnPlanner.getTime() > (defaultTask.snoozeUntil || new Date())?.getTime()
      ? dateBasedOnPlanner
      : null;

  if (!snoozeUntil && !!defaults.delayedStartInMinutes) {
    snoozeUntil = getUserDefaultSnoozeUntil(user, timePolicy);
  }

  const defaultDue: number | null = !defaults.dueInDays ? null : defaults.dueInDays || 3;

  return {
    ...defaultTask,
    snoozeUntil,
    timeChunksRequired: defaults.timeChunksRequired || 2,
    due: !!defaultDue ? getUserDefaultDueDate(user, timePolicy) : null,
    eventCategory: defaults.category.key === EventType.Work.key ? PrimaryCategory.SoloWork : PrimaryCategory.Personal,
    minChunkSize: defaults.minChunkSize || 1,
    maxChunkSize: defaults.maxChunkSize || 4,
    alwaysPrivate: defaults.alwaysPrivate,
  } as Task;
}
