import { EventKey } from "../reclaim-api/Events";
import { Recurrence } from "../reclaim-api/OneOnOnes";

export function parseEventKey(eventKey: EventKey): { eventId: string; calendarId: number } {
  const [, calendarIdStr, eventId] = eventKey.match(/^(\d+)\/(.+)$/) || [];
  const calendarId = parseInt(calendarIdStr);
  if (!calendarIdStr || isNaN(calendarId) || !eventId) throw new Error(`Invalid eventKey format: ${eventKey}`);
  return { calendarId, eventId };
}

export function getEffectiveRecurrence(recurrence?: Recurrence | null, timesPerPeriod?: number): Recurrence {
  if (!recurrence) {
    if (timesPerPeriod === 7) return Recurrence.Daily;
    else return Recurrence.Weekly;
  }

  return recurrence;
}
